<template>
  <el-form :ref="refs.serviceValidation" :model="serviceInfo" :rules="rules" class="h-full overflow-auto">
    <div class="object-service__details flex flex-col gap-4 pl-4 p-5">
      <div class="relative">
        <span class="object-service__info__label input-label">
          {{ $t('new_units.objects.fields.service.fields.service') }}
        </span>
        <el-form-item :rules="rules.name" prop="name">
          <el-input v-model="serviceInfo.name" @focus="$event.target.select()" />
        </el-form-item>
      </div>

      <div class="relative">
        <span class="object-trailers__details__label input-label">
          {{ $t('new_units.objects.fields.service.fields.description') }}
        </span>
        <el-form-item :rules="rules.description" prop="description">
          <el-input v-model="serviceInfo.description" rows="4" type="textarea" />
        </el-form-item>
      </div>

      <el-form-item :rules="rules.lastDays" prop="lastDays">
        <UnitsDateTimePicker
          :date="getLastDays(serviceInfo.lastDays)"
          :label="$t('new_units.objects.fields.service.fields.last_serviced')"
          format="dd/MM/yyyy HH:mm"
          type="datetime"
          @onChange="
            e => {
              changeUnitsDateTime(e)
            }
          "
        />
      </el-form-item>

      <div class="flex flex-col gap-3">
        <el-form-item :rules="rules.activeKm" class="checkbox-item" prop="isActiveKm">
          <UnitsCheckbox :label="$t('new_units.objects.fields.service.fields.mileage_period')" :value="serviceInfo.isActiveKm" name="activeKm" @onChange="changeCheckboxTrigger($event, 'isActiveKm', ['lastKm', 'intervalKm'])" />
        </el-form-item>

        <transition name="fade">
          <div v-if="serviceInfo.isActiveKm" class="flex gap-2 items-center justify-center bg-red-50 text-warningText py-2 px-3 w-100 rounded-12 text-xs mt-2 mb-3">
            <img src="@/assets/icons/attention.svg" class="w-6" alt="" />
            <span class="leading-4">
              {{ $t('new_units.objects.fields.service.fields.mileage_period.alert') }}
            </span>
          </div>
        </transition>

        <div class="flex gap-3">
          <div class="relative">
            <span class="object-service__info__label input-label">
              {{ $t('new_units.objects.fields.service.fields.current_mileage') }}
            </span>
            <el-form-item :rules="rules.lastKm" prop="lastKm">
              <el-input v-model="serviceInfo.lastKm" :disabled="!serviceInfo.isActiveKm" type="number" />
            </el-form-item>
          </div>

          <div class="relative">
            <span class="object-service__info__label input-label">
              {{ $t('new_units.objects.fields.service.fields.interval') }}
            </span>
            <el-form-item :rules="rules.intervalKm" prop="intervalKm">
              <el-input v-model="serviceInfo.intervalKm" :disabled="!serviceInfo.isActiveKm" type="number" @input="intervalChange" />
            </el-form-item>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-3">
        <el-form-item :rules="rules.activeDays" class="checkbox-item" prop="isActiveDays">
          <UnitsCheckbox :label="$t('new_units.objects.fields.service.fields.days_period')" :value="serviceInfo.isActiveDays" @onChange="changeCheckboxTrigger($event, 'isActiveDays', ['intervalDays'])" />
        </el-form-item>

        <div class="relative">
          <span class="object-service__info__label input-label">
            {{ $t('new_units.objects.fields.service.fields.interval_days') }}
          </span>
          <el-form-item :rules="rules.intervalDays" prop="intervalDays">
            <el-input v-model="serviceInfo.intervalDays" :disabled="!serviceInfo.isActiveDays" type="number" />
          </el-form-item>
        </div>
      </div>

      <div class="flex flex-col gap-3">
        <el-form-item :rules="rules.activeMotohours" class="checkbox-item" prop="isActiveMotohours">
          <UnitsCheckbox :label="$t('new_units.objects.fields.service.fields.ativate_engine_hours')" :value="serviceInfo.isActiveMotohours" @onChange="changeCheckboxTrigger($event, 'isActiveMotohours', ['lastMotohours', 'intervalMotohours'])" />
        </el-form-item>

        <transition name="fade">
          <div v-if="serviceInfo.isActiveMotohours" class="flex gap-2 items-center justify-center bg-red-50 text-warningText py-2 px-3 w-100 rounded-12 text-xs mt-2 mb-3">
            <img class="w-6" src="@/assets/icons/attention.svg" alt="" />
            <span class="leading-4">
              {{ $t('new_units.objects.fields.service.fields.mileage_period.alertSecond') }}
            </span>
          </div>
        </transition>

        <div class="flex gap-3">
          <div class="relative">
            <span class="object-service__info__label input-label">
              {{ $t('new_units.objects.fields.service.fields.last_times') }}
            </span>
            <el-form-item :rules="rules.lastMotohours" prop="lastMotohours">
              <el-input v-model="serviceInfo.lastMotohours" :disabled="!serviceInfo.isActiveMotohours" type="number" />
            </el-form-item>
          </div>
          <div class="relative">
            <span class="object-service__info__label input-label">
              {{ $t('new_units.objects.fields.service.fields.engine_hours') }}
            </span>
            <el-form-item :rules="rules.intervalMotohours" prop="intervalMotohours">
              <el-input v-model="serviceInfo.intervalMotohours" :disabled="!serviceInfo.isActiveMotohours" type="number" @input="intervalChange" />
            </el-form-item>
          </div>
        </div>
      </div>
    </div>
  </el-form>
</template>

<script setup>
import { ref, watch, onMounted, defineExpose } from 'vue'
import { useI18n } from '@/hooks/useI18n'
import { refs } from '@/components/unitsNew/helpers/index.js'
import UnitsCheckbox from '@/components/unitsNew/components/UnitsCheckbox.vue'
import UnitsDateTimePicker from '@/components/unitsNew/components/UnitsDateTimePicker.vue'
import { trackingApi } from '@/api'
import moment from 'moment'

// data
const $t = useI18n()
const emits = defineEmits(['onIntervalChange'])
const props = defineProps({
  service: {
    type: Object,
    default: () => ({})
  },
  unit: {
    type: Object,
    default: () => ({})
  }
})
onMounted(() => {
  if (props.service.lastDays === '') {
    // set lastdays to current date
    props.service.lastDays = moment().format('YYYY-MM-DD HH:mm:ss')
  }
})
const createFormData = (unitId, from) => ({
  units: [{ id: unitId }],
  from: moment(from).format('YYYY-MM-DD HH:mm:ss')
})

const changeUnitsDateTime = async event => {
  serviceInfo.value.lastDays = event
  await getTrackingPoints()
}

const getTrackingPoints = async () => {
  try {
    const fromDate = props.service && props.service.lastDays ? new Date(props.service.lastDays) : moment().format('YYYY-MM-DD HH:mm:ss') //
    const formData = createFormData(props.unit.id, fromDate)
    const res = await trackingApi.postPoint({ formData })

    serviceInfo.value.lastDays = props.service && props.service.lastDays ? moment(new Date(props.service.lastDays)).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss')

    if (!res.data.columns) return
    const mileage = res.data.columns.find(item => item.type === 'mileage')
    const motohours = res.data.columns.find(item => item.type === 'abs_motohours')

    if (mileage) {
      serviceInfo.value.lastKm = mileage.value
    }
    if (motohours) {
      serviceInfo.value.lastMotohours = motohours.value
    }
  } catch (error) {
    console.log('error getTrackingPoints', error)
  }
}
//  делаем expose чтобы вызывать в родительском компоненте
defineExpose({
  getTrackingPoints
})
const serviceInfo = ref({})

const checkboxFields = ['isActiveKm', 'isActiveDays', 'isActiveMotohours']

const getLastDays = value => {
  if (!value) return value

  const time = value.split(' ')

  time[1] = time[1] || '00:00:00'

  return time.join(' ')
}

const changeCheckboxTrigger = (value, prop, fields) => {
  serviceInfo.value[prop] = value
  console.log('changeCheckboxTrigger', value, prop, fields)

  fields.forEach(field => {
    console.log('Validate ', field)
    refs.serviceValidation.value.validateField(field)
  })

  checkboxFields.forEach(field => {
    console.log('Validate ', field)
    refs.serviceValidation.value.validateField(field)
  })
}

const validateIntervalMotohours = (rule, value, callback) => {
  if (!serviceInfo.value.isActiveMotohours || (serviceInfo.value.intervalMotohours && serviceInfo.value.intervalMotohours.toString().length > 0)) {
    callback()
  } else {
    callback(new Error())
  }
}

const validateIntervalDays = (rule, value, callback) => {
  if (!serviceInfo.value.isActiveDays || (serviceInfo.value.intervalDays && serviceInfo.value.intervalDays.toString().length > 0)) {
    callback()
  } else {
    callback(new Error())
  }
}

const validateLastMotohours = (rule, value, callback) => {
  if (!serviceInfo.value.isActiveMotohours || (serviceInfo.value.lastMotohours && serviceInfo.value.lastMotohours.toString().length > 0)) {
    callback()
  } else {
    callback(new Error())
  }
}

const validateLastKm = (rule, value, callback) => {
  if (!serviceInfo.value.isActiveKm || (serviceInfo.value.lastKm && serviceInfo.value.lastKm.toString().length > 0)) {
    callback()
  } else {
    callback(new Error())
  }
}

const validateIntervalKm = (rule, value, callback) => {
  if (!serviceInfo.value.isActiveKm || (serviceInfo.value.intervalKm && serviceInfo.value.intervalKm.toString().length > 0)) {
    callback()
  } else {
    callback(new Error())
  }
}

const validateCheckbox = (rule, value, callback) => {
  if (serviceInfo.value.isActiveKm || serviceInfo.value.isActiveDays || serviceInfo.value.isActiveMotohours) {
    callback()
  } else {
    callback(new Error())
  }
}

const rules = {
  name: [{ required: true, message: $t('new_units.objects.fields.service.errors.name') }],
  description: [{ required: true, message: $t('new_units.objects.fields.service.errors.description') }],
  lastDays: [{ required: true, message: $t('new_units.objects.fields.service.errors.lastDays') }],
  lastKm: [
    {
      validator: validateLastKm,
      required: true,
      message: $t('new_units.objects.fields.service.errors.intervalKm')
    }
  ],
  intervalKm: [
    {
      validator: validateIntervalKm,
      required: true,
      message: $t('new_units.objects.fields.service.errors.intervalKm')
    }
  ],
  intervalDays: [
    {
      required: true,
      validator: validateIntervalDays,
      message: $t('new_units.objects.fields.service.errors.intervalDays')
    }
  ],
  lastMotohours: [
    {
      required: true,
      validator: validateLastMotohours,
      message: $t('new_units.objects.fields.service.errors.lastMotohours')
    }
  ],
  intervalMotohours: [
    {
      required: true,
      validator: validateIntervalMotohours,
      message: $t('new_units.objects.fields.service.errors.intervalMotohours')
    }
  ],
  activeKm: [
    {
      validator: validateCheckbox,
      trigger: 'change',
      message: $t('new_units.objects.fields.service.errors.checkbox')
    }
  ],
  activeDays: [
    {
      validator: validateCheckbox,
      trigger: 'change',
      message: $t('new_units.objects.fields.service.errors.checkbox')
    }
  ],
  activeMotohours: [
    {
      validator: validateCheckbox,
      trigger: 'change',
      message: $t('new_units.objects.fields.service.errors.checkbox')
    }
  ]
}

const intervalChange = () => {
  let second_column = ' / '

  if (props.service.intervalKm) second_column = props.service.intervalKm + second_column
  if (props.service.intervalMotohours) second_column += props.service.intervalMotohours

  second_column = second_column === ' / ' ? '' : second_column

  props.service.second_column = second_column

  emits('onIntervalChange')
}

watch(
  () => props.service,
  service => {
    if (Object.keys(props.service).length) {
      const propertiesToString = ['intervalDays', 'lastKm', 'intervalKm', 'intervalMotohours', 'lastMotohours']
      serviceInfo.value = service

      // Rules elements-ui ругается на то что типа даннх не строка
      propertiesToString.forEach(prop => {
        if (typeof serviceInfo.value[prop] !== 'undefined') {
          serviceInfo.value[prop] = serviceInfo.value[prop].toString()
        }
      })
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
